import { useState, useEffect, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import './home.css';
import Navbar5 from './Navbar5';
import Navbar1 from './Navbar1';
import Banner from './Banner';
import Row4 from './Row4';
import requests from './requests';
import Spinner2 from './Spinner2';
import categories from '../utils/categories';
import PreviewHover from '../components/previewHover';
import PreviewDetail from '../components/previewDetails';

function Home3() {
	window.sessionStorage.clear();

	const [windowScrolling, setWindowScrolling] = useState(false);

	const [isLoading, setIsLoading] = useState(true);
	const [visibleCount, setVisibleCount] = useState(5); // Number of categories to load initially and incrementally
	const [popUpState, setPopUpState] = useState({
		top: 0,
		left: 0,
		movie: {}
	})

	const [clickedState, setClickedState] = useState({
		movie: {}
	})

	useEffect(() => {
		// Simulate data loading delay
		const timeout = setTimeout(() => {
			setIsLoading(false);
		}, 2000); // Replace 2000 with the actual loading time for the first row

		return () => clearTimeout(timeout);
	}, []);

	useEffect(() => {
		const handleScroll2 = () => {
			// console.log("is scroll");
			setWindowScrolling(true);
			// Additional logic for handling scrolling if needed
			setPopUpState({
				top: 0,
				left: 0,
				movie: {}
			})
		};

		const handleScrollEnd = () => {
			setWindowScrolling(false);
		};

		document.querySelector('body').addEventListener('scroll', handleScroll2);
		document.querySelector('body').addEventListener('scrollend', handleScrollEnd);

		return () => {
			window.removeEventListener('scroll', handleScroll2);
			window.removeEventListener('scrollend', handleScrollEnd);
		};
	}, []);


	const handleNextRowLoad = () => {
		// Set isLoading to true when the next row loads
		setIsLoading(true);

		// Simulate data loading delay for the next row
		setTimeout(() => {
			setIsLoading(false);
		}, 2000); // Replace 2000 with the actual loading time for the next row
	};


	const handleMouseEventOverImage = (movie, event) => {
		// console.log("movie", movie);
		if (windowScrolling || window.innerWidth <= 600) {
			return;
		}

		const imagePosition = event.target.getBoundingClientRect();


		setPopUpState({
			left: imagePosition.left + imagePosition.width - 60 + "px", // Adjust the left position as needed
			top: imagePosition.top - 50 + "px",
			movie: movie
		})
	}

	const handleMouseLeave = () => {
		if (popUpState.movie?.id) {
			setPopUpState({
				top: 0,
				left: 0,
				movie: {}
			})
		}
	}

	const visibleCategories = useMemo(() => categories.slice(0, visibleCount), [visibleCount]);

	// Determine if more categories are available
	const hasMore = useMemo(() => visibleCount < categories.length, [visibleCount]);

	// Load more categories by increasing the visible count
	const loadMoreCategories = () => {
		setVisibleCount((prev) => prev + 5); // Increase by 5 categories on each load
	};

  	// const isLoggedIn = Cookies.get('isLoggedIn');
	// const userId = Cookies.get('userId');

	// console.log('isLoggedIn:', isLoggedIn);
	// console.log('userId:', userId);

	return (
		<div className="container-fluid" id="mainContainer">
			<div className="row">
				<div className="col-12 col-sm-12 text-white p-0 home__div1">
					<Navbar5 {...clickedState} />
					<Banner />

					<PreviewHover setPopUpState={setPopUpState}  {...popUpState} />
					<PreviewDetail {...clickedState} />
					{isLoading && <Spinner2 />}
					<InfiniteScroll
						dataLength={visibleCategories.length}
						next={loadMoreCategories}
						hasMore={hasMore}
						loader={<h4>Loading...</h4>}
					>
						{
							visibleCategories.map((category) => (
								<Row4
									key={category.title}
									title={category.title}
									fetchUrl={category.fetchUrl}
									isLargeRow={true}
									onLoaded={handleNextRowLoad}
									handleMouseEventOverImage={handleMouseEventOverImage}
									handleMouseLeave={handleMouseLeave}
									handleMoviePreview={(movie) => {
										setClickedState({
											movie
										})
									}}
								/>
							))
						}
					</InfiniteScroll>

					<div style={{ height: '60px' }}></div>
					<Navbar1 />
				</div>
			</div>
		</div>
	);
}

export default Home3;