import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './pricing.css';
import { useWallet } from '@solana/wallet-adapter-react';
import Cookies from 'js-cookie';
import axios from 'axios';
import Spinner2 from './Spinner2';

function Pricing() {
	const [plans, setPlans] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const navigate = useNavigate();

	const userId = Cookies.get('userId');
	// console.log(userId);

    const { connected } = useWallet();

	const btnClickHandler = (id) => {
		if (userId && connected) {
			navigate(`/v1/paynow/${id}`);
		}
		else {
			navigate(`/v1/payBtn2/?type=crown&id=${id}`);
		}
	}

	const fetchData = async () => {
		const response = await axios.get('https://mateys.xyz/web_api/admin/getPlans.php', {
	        headers: {
          	'accept': 'application/json', // Set the content type for FormData
        	},
	    });

	    // console.log(response.data.filter(i => i.amount !== ''));

	    const data2 = response.data.filter(i => i.amount !== '');

	    setPlans(data2);
	    setIsLoading(false);
	}

	useEffect(() => {
		fetchData();
	}, [])

	return (
		<div className="container-fluid p__div1">
			<div className="row">
				{isLoading && <Spinner2 />}

				<div className="col-12 col-sm-12 text-center text-white border-0 p__div2">
					<h3>PREMIUM</h3>
				</div>

				<div className="col-12 col-sm-12 text-center mt-5 w-100 p__div5">
					<div className="row p__div6 w-100">
						{plans && plans.map((i, index) => (
							<div 
								className="card bg-transparent border-light text-white h-100"
								key={index}
							>
							    <div className="card-body">
							        {Math.floor(i.duration / 30) > 0 && Math.floor(i.duration / 30) < 12 && (
									  <div className="text-center">{Math.floor(i.duration / 30)} month</div>
									)}
									{Math.floor(i.duration / 365) >= 2 ? (
									  <div className="text-center">{Math.floor(i.duration / 365)} years</div>
									) : (
									  Math.floor(i.duration / 365) > 0 && (
									    <div className="text-center">{Math.floor(i.duration / 365)} year</div>
									  )
									)}
							        <div className="p__div__price text-center">{i.amount} SOL</div>
							    </div>
							    <div className="pricing">
								    <ul className="list-group list-group-flush border-0" style={{ 'listStyleType': 'circle' }}>
									    <li className="list-group-item bg-transparent text-white text-start" style={{ transition: 'none' }}>review content out of stars (1-10)</li>
									    <li className="list-group-item bg-transparent text-white text-start" style={{ transition: 'none' }}>comment on each post</li>
									    <li className="list-group-item bg-transparent text-white text-start" style={{ transition: 'none' }}>no Ads</li>
									</ul>
								</div>
								
							    <div className="card-footer">
									<button className="btn border text-white" onClick={() => btnClickHandler(i.id)}>Subscribe</button>
							    </div>
							</div>
						))}
					</div>
				</div>

				<div className="col-12 col-sm-12" style={{ 'height': '60px' }}></div>
			</div>
		</div>
	);
}

export default Pricing;