import React, { useState, useEffect, useCallback } from 'react';
import axios from './axios';
import { useNavigate, Link } from 'react-router-dom';
import { Image, Shimmer } from 'react-shimmer';
import ReactPlayer from 'react-player/lazy';
import Spinner2 from './Spinner2';
import './row1.css';
import Cookies from 'js-cookie';

function Row4({ title, fetchUrl, isLargeRow,handleMouseEventOverImage,handleMouseLeave ,handleMoviePreview}) {
  const [movies, setMovies] = useState([]);
  // const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [popupData, setPopupData] = useState(null);
  const [previewPosition, setPreviewPosition] = useState({ left: 0, top: 0 });
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [clickData, setClickData] = useState(null);
  const [epName, setEpName] = useState(null);

  const [seasons, setSeasons] = useState(null);
  const [collection, setCollection] = useState(null);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [episodeData, setEpisodeData] = useState(null);
  const [epId, setEpId] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [similarMovies, setSimilarMovies] = useState(null);
  const [genre, setGenre] = useState(null);
  const [cast, setCast] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  // const [isScrolling, setIsScrolling] = useState(false);
  const [popupTimeoutId, setPopupTimeoutId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [mType, setMType] = useState('movie');
  const [data, setData] = useState([]);
  const [total, setTotal] = useState('');

  // const location = useLocation();

  const navigate = useNavigate();

  const isPremium = Cookies.get('bool');
  // console.log(isPremium);
  window.sessionStorage.clear();

  const base_url = 'https://image.tmdb.org/t/p/original';

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(fetchUrl, {          
        headers: {
          accept: 'application/json',
          Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJkYmQzOGRlNzRlYTUwZDRkNDE5Mzk0OTM0OTczYTA0MCIsInN1YiI6IjY1OWUyYzkzOGU4ZDMwMDE0YzIwMjExYyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.D0rTluAv1sY7ne6WtCShIY8TfJIsx7HQ6FajTpbXC-w',
        },
      });

      if (response.data.hasOwnProperty('results')) {
        // console.log(response.data.results);

        // setMovies((prevMovies) => [...prevMovies, ...response.data.results]);
        // setPage((prevPage) => prevPage + 1);
        setMovies(response.data?.results);
      }
      else {
        setMovies(response.data?.items);
      }
      setLoading(false);
      setIsLoading(false);

      return response;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [fetchUrl]);

  useEffect(() => {
    fetchData();
  }, [fetchUrl]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const closePopup = useCallback(() => {
    // Clear the timeout and close the popup
    clearTimeout(popupTimeoutId);
    setPopupData(null);
    setIsOpen(false);
  },[popupTimeoutId]);


  const movieClickHandler = (movie) => {
    let name = movie?.original_title || movie?.name || movie?.original_name || movie?.title;
    let id = movie?.show_id || movie?.id;
    // console.log(epName, id, movie, name);

    const url = `/v1/moviePart/${id}?name=${name}&episode=&sno=`;
    navigate(url);
  };

  const showClickHandler = async (movie) => {
    // console.log(movie);
    let name = movie?.title || movie?.name || movie?.original_name || movie?.original_title;
    let id = movie?.id;

    setEpName(name);
    setTotal('');
    setData([]);

    const response = await axios.get(`/search/multi?query=${name}&include_adult=false&language=en-US&page=1`, {
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJkYmQzOGRlNzRlYTUwZDRkNDE5Mzk0OTM0OTczYTA0MCIsInN1YiI6IjY1OWUyYzkzOGU4ZDMwMDE0YzIwMjExYyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.D0rTluAv1sY7ne6WtCShIY8TfJIsx7HQ6FajTpbXC-w'
      }
    });

    // console.log(response.data.results);

    let filterData = response.data.results.filter(i => {
      return i.id === id;
    });

    if (filterData.length === 0) {
      filterData = response.data.results.filter((i, index) => {
        return index === 0;
      });
    }

    // console.log(filterData, filterData[0].media_type);

    let mediaType = "movie";

    if (filterData[0]?.media_type === 'tv') {
      mediaType = "tv";
      setMType(mediaType);
    }

    else if (filterData[0]?.media_type === 'movie') {
      mediaType = "movie"
      setMType(mediaType);
    }

    const response2 = await axios.get(`/${mediaType}/${filterData[0]?.id}?append_to_response=credits&language=en-US`, {
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJkYmQzOGRlNzRlYTUwZDRkNDE5Mzk0OTM0OTczYTA0MCIsInN1YiI6IjY1OWUyYzkzOGU4ZDMwMDE0YzIwMjExYyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.D0rTluAv1sY7ne6WtCShIY8TfJIsx7HQ6FajTpbXC-w'
      }
    });

    setCast(response2.data.credits?.cast);
    setGenre(response2.data?.genres);

    // console.log(response2.data);

    if (response2.data.hasOwnProperty('number_of_seasons') && response2.data?.number_of_seasons !== null) {
      const sID = response2.data?.id;
      const sid = response2.data?.seasons[0].id;

      // console.log(sID, sid);
      const filteredSeasons = response2.data?.seasons.filter(season => season.season_number !== 0);
      // console.log(response2.data?.seasons);
      // console.log(filteredSeasons);

      const response5 = await axios.get(`/tv/${sID}/similar`, {
        headers: {
          accept: 'application/json',
          Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJkYmQzOGRlNzRlYTUwZDRkNDE5Mzk0OTM0OTczYTA0MCIsInN1YiI6IjY1OWUyYzkzOGU4ZDMwMDE0YzIwMjExYyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.D0rTluAv1sY7ne6WtCShIY8TfJIsx7HQ6FajTpbXC-w'
        }
      })

      // console.log(response5.data?.results);

      setEpId(response2.data?.id);
      setSeasons(filteredSeasons);
      setClickData(response2.data);
      setSimilarMovies(response5.data?.results);
      // setSelectedSeason(sid);

      const formData = new FormData();
      formData.append('tmdbId', sID);

      const response50 = await axios.post(`https://mateys.xyz/web_api/get_video_donors.php`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        maxBodyLength: Infinity,
      });

      setData(response50.data);

      return response2.data;
    }

    else if (response2.data.hasOwnProperty('belongs_to_collection') && response2.data?.belongs_to_collection !== null) {
      // console.log(response2.data);

      if (response2.data?.belongs_to_collection !== '') {
        const id = response2.data.belongs_to_collection?.id;
        const sID = response2.data?.id;
        setEpId(response2.data?.id);

        // const url = `/collection/${id}`;
        const response4 = await axios.get(`/collection/${id}`, {
          headers: {
            accept: 'application/json',
            Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJkYmQzOGRlNzRlYTUwZDRkNDE5Mzk0OTM0OTczYTA0MCIsInN1YiI6IjY1OWUyYzkzOGU4ZDMwMDE0YzIwMjExYyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.D0rTluAv1sY7ne6WtCShIY8TfJIsx7HQ6FajTpbXC-w'
          }
        })

        // console.log(response4.data);

        const response5 = await axios.get(`/movie/${sID}/similar`, {
          headers: {
            accept: 'application/json',
            Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJkYmQzOGRlNzRlYTUwZDRkNDE5Mzk0OTM0OTczYTA0MCIsInN1YiI6IjY1OWUyYzkzOGU4ZDMwMDE0YzIwMjExYyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.D0rTluAv1sY7ne6WtCShIY8TfJIsx7HQ6FajTpbXC-w'
          }
        })

        // console.log(response5.data.results);

        setSimilarMovies(response5.data.results);
        setCollection(response4.data?.parts);
        setSelectedCollection(response4.data.parts[0]?.id);

        const formData = new FormData();
        formData.append('tmdbId', id);

        const response50 = await axios.post(`https://mateys.xyz/web_api/get_video_donors.php`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          maxBodyLength: Infinity,
        });

        setData(response50.data);
      }
      setClickData(response2.data);
      return response2.data;
    }

    else {
      // console.log("3");

      const response5 = await axios.get(`/${mediaType}/${id}/similar`, {
        headers: {
          accept: 'application/json',
          Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJkYmQzOGRlNzRlYTUwZDRkNDE5Mzk0OTM0OTczYTA0MCIsInN1YiI6IjY1OWUyYzkzOGU4ZDMwMDE0YzIwMjExYyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.D0rTluAv1sY7ne6WtCShIY8TfJIsx7HQ6FajTpbXC-w'
        }
      })

      setSimilarMovies(response5.data.results);
      setClickData(response2.data);

      const formData = new FormData();
      formData.append('tmdbId', id);

      const response50 = await axios.post(`https://mateys.xyz/web_api/get_video_donors.php`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        maxBodyLength: Infinity,
      });

      setData(response50.data);

      return response2.data;
    }
  }

  const handleClick = (movie) => {
    if (width >= 600) {
      handleMoviePreview(movie);
    } else {

      movieClickHandler(movie);
    }
  };

  useEffect(() => {
    if (data.length >= 1) {
      const totalAmount = data.reduce((acc, red) => {
        return acc + parseFloat(red.total_donation);
      }, 0);

      setTotal(totalAmount);
    }
  }, [data]);

  return (
    <>
      {isLoading ? (
        <Spinner2 />
      ) : (
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-sm-12 mb-2 text-uppercase title">{title}</div>
            <div className="col-12 col-sm-12 border-0 imgScroller" style={{ whiteSpace: 'nowrap', overflowX: 'scroll' }}>
              {movies.map((movie, index) => (
                <div
                  key={index}
                  onClick={() => handleClick(movie)}
                  onMouseOver={(event) => handleMouseEventOverImage(movie, event)}
                   onMouseOut={handleMouseLeave}
                  className={`imgContainer ${isLargeRow ? 'row_poster_large' : ''}`}
                >
                  <Image
                    className="border"
                    decoding="async"
                    key={`${base_url}${movie?.poster_path}`}
                    src={`${base_url}${isLargeRow ? movie?.poster_path : movie?.backdrop_path || movie?.poster_path}`}
                    alt="gb.tv"
                    fallback={<Shimmer width={200}  height={270} colorShimmer={['green', 'white', 'red']} />}
                  />
                </div>
              ))}
              {loading && <Spinner2 />}
            </div>            
          </div>
        </div>
      )}
    </>
  );
}

export default Row4;
