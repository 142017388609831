import requests from "../pages/requests";

const categories = [
  {
    title: "new releases",
    fetchUrl: requests.fetchNewReleases,
    initial_page: 1,
  },
  {
    title: "pirated.tv originals",
    fetchUrl: requests.fetchNetflixOriginals,
    initial_page: 1,
  },
  {
    title: "trending now",
    fetchUrl: requests.fetchTrending,
    initial_page: 1,
  },
  {
    title: "top rated",
    fetchUrl: requests.fetchTopRated,
    initial_page: 1,
  },
  {
    title: "action movies",
    fetchUrl: requests.fetchActionMovies,
    initial_page: 1,
  },
  {
    title: "comedy movies",
    fetchUrl: requests.fetchComedyMovies,
    initial_page: 1,
  },
  {
    title: "horror movies",
    fetchUrl: requests.fetchHorrorMovies,
    initial_page: 1,
  },
  {
    title: "romance movies",
    fetchUrl: requests.fetchRomanceMovies,
    initial_page: 1,
  },
  {
    title: "documentaries",
    fetchUrl: requests.fetchDocumentaries,
    initial_page: 1,
  },
  {
    title: "Adventure",
    fetchUrl: requests.fetchAdventure,
    initial_page: 1,
  },
  {
    title: "Animation",
    fetchUrl: requests.fetchAnimation,
    initial_page: 1,
  },
  {
    title: "Crime",
    fetchUrl: requests.fetchCrime,
    initial_page: 1,
  },
  {
    title: "Drama",
    fetchUrl: requests.fetchDrama,
    initial_page: 1,
  },
  {
    title: "Mystery",
    fetchUrl: requests.fetchMystery,
    initial_page: 1,
  },
  {
    title: "Thriller",
    fetchUrl: requests.fetchThriller,
    initial_page: 1,
  },
  {
    title: "Fantasy",
    fetchUrl: requests.fetchFantasy,
    initial_page: 1,
  },
  {
    title: "History",
    fetchUrl: requests.fetchHistory,
    initial_page: 1,
  },
  {
    title: "Science & Fiction",
    fetchUrl: requests.fetchScienceFiction,
    initial_page: 1,
  },
  {
    title: "War",
    fetchUrl: requests.fetchWar,
    initial_page: 1,
  },
  {
    title: "Western",
    fetchUrl: requests.fetchWestern,
    initial_page: 1,
  },
  {
    title: "Children & Family",
    fetchUrl: requests.fetchChildrenFamily,
    initial_page: 1,
  },
];

export default categories