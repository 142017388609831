import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player';
import axios from '../../pages/axios';


/**
 * 
 * @param {
 *  props:{
 *  top:number;
 * left:number;
 * movie:Object
 * setPopUpState:SetState
 * 
 * }
 *  
 * }
 *  
 * @returns 
 */


const Index = ({ top, left, movie, setPopUpState }) => {

    const [popupData, setPopupData] = useState(null);
    const [isAudioMuted, setIsAudioMuted] = useState(false);
    const timeRef = useRef(null)
    const closePopup = useCallback(() => {
        // Clear the timeout and close the popup
        clearTimeout(timeRef?.current);
        setPopupData(null);
        setPopUpState({
            top: 0,
            left: 0,
            movie: {}
        })
    }, []);



    const fetchMovieDataAsync = (movie) => {

        let name = movie?.original_title || movie?.name || movie?.original_name || movie?.title;
        let id = movie.id;
        if (!id) {
            return;
        }
        if (timeRef.current)
            clearTimeout(timeRef.current)

        timeRef.current = setTimeout(async () => {
            const response2 = await axios.get(`/search/multi?query=${name}&include_adult=false&language=en-US&page=1`, {
                headers: {
                    accept: 'application/json',
                    Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJkYmQzOGRlNzRlYTUwZDRkNDE5Mzk0OTM0OTczYTA0MCIsInN1YiI6IjY1OWUyYzkzOGU4ZDMwMDE0YzIwMjExYyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.D0rTluAv1sY7ne6WtCShIY8TfJIsx7HQ6FajTpbXC-w'
                }
            });

            const filterData = response2.data.results.filter(i => {
                return i.id === id;
            })

            let mediaType = "movie";

            if (filterData[0].media_type === 'tv') {
                mediaType = "tv";
            }
            else if (filterData[0].media_type === 'movie') {
                mediaType = "movie"
            }

            const videosResponse = await axios.get(`/${mediaType}/${id}/videos?language=en-US`, {
                headers: {
                    accept: 'application/json',
                    Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJkYmQzOGRlNzRlYTUwZDRkNDE5Mzk0OTM0OTczYTA0MCIsInN1YiI6IjY1OWUyYzkzOGU4ZDMwMDE0YzIwMjExYyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.D0rTluAv1sY7ne6WtCShIY8TfJIsx7HQ6FajTpbXC-w'
                },
            });

            setPopupData({
                name,
                videos: videosResponse.data.results[0]?.key,
                overview: movie?.overview
            });

        }, 100);


    };


    useEffect(() => {
        if (movie?.id) {
            setPopupData(null)
            fetchMovieDataAsync(movie)
        }
    }, [movie])

    function truncate(str, n) {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    }
    const handleClosePreview = () => {
        setPopupData(null);
        setPopUpState({
            top: 0,
            left: 0,
            movie: {}
        })
    };


    const popupClickHandler = () => {
        setTimeout(() => {
            const iframe = document.querySelector('.preview .row_video1');
            if (iframe) {
                iframe.requestFullscreen(); // Request full screen for the iframe
            }
        }, 100);
    };


    return (
        <div className="col-12 col-sm-12 mb-2" style={{ overflowY: 'hidden !important' }} key={movie?.id || '1'}>
            {movie.id && popupData && (
                <div className="preview" style={{ position: 'fixed', left: left, top: top }}>
                    <ReactPlayer
                        url={`https://www.youtube.com/embed/${popupData.videos}?autoplay=1&mute=1&controls=0&enablejsapi=1&origin=https://mateys.xyz/`}
                        className="row_video1"
                        width="100%"
                        height="auto"
                        controls={false}
                        loop={true}
                        playsinline={true}
                        volume={0.5}
                        muted={isAudioMuted}
                        playing={true}                        
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        config={{ youtube: { playerVars: { disablekb: 1 } } }}
                        fullscreen="true"
                        allowFullScreen={true}
                        onReady={() => console.log('Player is ready')}
                        onError={(error) => console.error('Error loading video:', error)}
                    />
                    <div className="d-flex mt-2 justify-content-between align-items-center ps-2 pe-2 vBtn">
                        <p className="" style={{ fontSize: '16px', wordSpacing: '1px' }}>{movie?.original_title || movie?.name || movie?.original_name || movie?.title}</p>
                        <button
                            className="btn banner__button2 audio-button border-0"
                            onClick={() => setIsAudioMuted((prevMuted) => !prevMuted)}
                        >
                            {isAudioMuted ? (
                                <i className="fa-solid fa-volume-xmark" style={{ color: '#00000' }}></i>
                            ) : (
                                <i className="fa-solid fa-volume-high" style={{ color: '#00000' }}></i>
                            )}
                        </button>
                        <button className="btn full-btn border-0 p-0 d-none" onClick={popupClickHandler}>
                            <i className="fa-solid fa-down-left-and-up-right-to-center" style={{ color: '#fcfcfc' }}></i>
                        </button>
                    </div>
                    <p className="ps-2 pe-2 div_description" style={{ wordSpacing: '0.5px' }}>{truncate(movie.overview, 120)}</p>
                    <button className="close-button border-0 bg-transparent" onClick={handleClosePreview} style={{ position: 'absolute', top: '10%', right: '10%', zIndex: 9999 }}>
                        <i className="fa-solid fa-circle-xmark" style={{ color: '#f2021a' }}></i>
                    </button>
                </div>
            )}
        </div>
    )
}

export default Index